import { graphql } from 'gatsby';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';
import React from 'react';

import Location from '../components/framework/location';
import Disclaimer from '../components/framework/disclaimer';
import PricesTable from '../components/framework/prices';
import Framework from '../components/framework';
import Seo from '../components/framework/seo';
import withLocation from '../middleware/withLocation';
import WaitTooltip from '../components/information/wait_tooltip';

const Prices = ({ location }) => {
	const { t } = useTranslation('prices');

	return (
		<Framework location={location}>
			<Seo
				description={t('meta_description')}
				tags={t('meta_keywords')}
				title={t('title')}
			/>

			<div className="container mt-3 pt-lg-5">
				<h1 className="h2">{t('t1')}</h1>
				<div className="my-4">
					<h2 className="h4">{t('t2')}</h2>
					<p>
						<Trans
							i18nKey="t3"
							components={{ WaitTooltip: <WaitTooltip /> }}
							ns="prices"
						/>
					</p>
				</div>
				<Disclaimer />
				<PricesTable types={['antigen', 'pcr']} />

				<h2 className="mb-2 mt-2 h4">{t('t4')}</h2>
				<Location />
				<div className="my-5">
					<h2 className="h4">{t('t5')}</h2>
					<p>{t('t6')}</p>
				</div>
			</div>
		</Framework>
	);
};

Prices.propTypes = {
	location: PropTypes.object.isRequired,
};

export default withLocation(Prices);

export const query = graphql`
	query {
		locales: allTranslation(filter: { id: { ne: "dummy" } }) {
			...translationFields
		}
	}
`;
